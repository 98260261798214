


















import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import ProductsMixin from "@/mixins/http/ProductsMixin";
import InlineMultiSelect from "@/components/InlineMultiSelect.vue";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import { Product } from "@/models";
import { easync } from "@/utils/http";
import { showErrorToast } from "@/utils/Toast";

const tag = "get_products";

@Component({ components: { InlineMultiSelect } })
export default class GeneralSettingMultiselect extends Mixins(
  ProductsMixin
) {
  @Prop() idSelected!: string | number;
  @Prop({ default: (): any[] => [] }) value: any[] | null;
  @Prop() params!: any;
  @Prop() formClass: string;
  @Prop() searchEnabled: boolean;
  @Prop() classes: string;
  @Prop() dataKeys?: Array<string>;
  @Prop() placeholder?: string;
  @Prop({ default: false }) actsLikeFilter?: boolean;
  @Prop({ required: false }) queryKey: string;
  @Prop({ default: true }) preselect?: boolean;
  @Prop() type: string;

  private options: any = [];

  private items: any = [];
  private id: any = this.idSelected || null;

  private filter: any = { ...this.params };

  @RegisterHttp(tag) getItemsTag: RequestData;

  get queryParams() {
    return { ...this.params };
  }

  mounted() {
    this.loadItems();
  }

  onSearch(term: string) {
    this.filter.term = term;
    this.loadItems();
  }

  onDataUpdated(data: any) {
    this.$emit("change", data);
  }

  onItemSelected(data: any) {
    this.$emit("change", data);
  }

  async loadItems() {
    const [data, errors] = await easync(this.getProducts({ filter: { ...this.filter } }, tag));
    if (errors) {
      showErrorToast();
      return;
    }

    this.items = data;
    this.$emit("loaded", this.items);
    this.options = data.map((o: Product) => {
      return { id: `${o.id}/${o.name}/${o.sku}`, name: o.name };
    });
  }

  onItemsReady() {
    if (this.idSelected) {
      this.id = this.idSelected;
    } else {
      if (this.preselect) {
        this.id =
          this.queryKey && this.$route.query[this.queryKey]
            ? this.$route.query[this.queryKey]
            : this.options[0]?.id;
      }
    }
  }
}
